<template xmlns="http://www.w3.org/1999/html">
  <el-card>
    <div class="export_user_data">
      <div class="module-title">
        导出数据库
      </div>
      <div>
        <el-checkbox-group style="margin-bottom: 20px" v-model="form.downloadCategory" >
          <el-checkbox :label="1">注册用户</el-checkbox>
          <el-checkbox :label="2">内部用户</el-checkbox>
        </el-checkbox-group>
      </div>
     <div style="margin-top: 10px" v-show="form.downloadCategory.length">
       <div class="module-title">
         导出条件
       </div>
       <el-card class="where" style="width:1000px;margin-bottom: 10px">
         <div class="title">
           基础条件
         </div>
         <nav class="input" style="width: 210px">
           <el-input v-model="searchForm.keyword" size="medium" placeholder="输入姓名，手机，邮箱，公司" />
         </nav>
         <nav class="input" style="width: 210px">
           <el-select v-model="searchForm.type" @change="searchForm.business = '',
           searchForm.subBusiness = '';" placeholder="行业类型" size="medium">
             <el-option label="全部" :value="-1" />
             <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id" />
           </el-select>
         </nav>
         <nav class="input" style="width: 210px">
           <el-select v-model="searchForm.business" @change="searchForm.subBusiness = '';" placeholder="一级行业" size="medium">
             <el-option label="全部" :value="-1" />
             <el-option v-for="item in businessList" :key="item.id" :label="item.name" :value="item.id" />
           </el-select>
         </nav>
         <nav class="input" style="width: 210px">
           <el-select v-model="searchForm.subBusiness" placeholder="二级行业" size="medium">
             <el-option label="全部" :value="-1" />
             <el-option v-for="item in subBusinessList" :key="item.id" :label="item.name" :value="item.id" />
           </el-select>
         </nav>
         <nav class="input" style="width: 210px">
           <el-select v-model="searchForm.waId" placeholder="小助手" size="medium">
             <el-option label="全部" :value="-1" />
             <el-option label="已绑定小助手" :value="-2" />
             <el-option label="未绑定小助手" :value="-3" />
             <el-option v-for="item in $store.state.NormalList" :key="item.id" :label="item.name" :value="item.id" />
           </el-select>
         </nav>
         <nav class="input" style="width: 310px">
           <el-select v-model="searchForm.province" placeholder="省份" size="medium" style="width: 150px"
                      @change="getc(searchForm.province)">
             <el-option label="全部" :value="-1" />
             <el-option v-for="item in p_arr" :key="item.code" :label="item.name" :value="item.code" />
           </el-select>
           <el-select v-model="searchForm.city" placeholder="城市"  size="medium" style="width: 150px" class="ml">
             <el-option label="全部" :value="-1" />
             <el-option v-for="item in c_arr" :key="item.code" :label="item.name" :value="item.code" />
           </el-select>
         </nav>
         <!--        <nav class="input" style="width: 150px">-->
         <!--          <improve-personnel :value.sync="form.amId"></improve-personnel>-->
         <!--        </nav>-->
         <nav class="input" style="width: 210px">
           <el-select v-model="searchForm.bindEnterprise" placeholder="绑定企业" size="medium">
             <el-option label="全部" :value="-1" />
             <el-option label="未绑定" :value="0" />
             <el-option label="已绑定" :value="1" />
           </el-select>
         </nav>
         <nav class="input" style="width: 210px">
           <el-select v-model="searchForm.jobLevel" placeholder="职级" size="medium">
             <el-option label="全部" :value="-1" />
             <el-option v-for="(item, index) in $store.state.config && $store.state.config.userJobLevel" :key="index"
                        :label="item" :value="index" />
           </el-select>
         </nav>
         <nav class="input" style="width: 210px">
           <el-select  v-model="searchForm.completeInfo" placeholder="完善状态" size="medium">
             <el-option label="全部" value="0" />
             <el-option label="已完善" value="2" />
             <el-option label="未完善" value="1" />
           </el-select>
         </nav>

         <nav class="input flex-align" style="width: 300px;display:flex">
           <el-date-picker v-model="searchForm.createTime" type="daterange" range-separator="至" start-placeholder="注册开始日期"
                           end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%;"
                           size="medium" />
         </nav>

       </el-card>
       <el-card class="where" v-if="where.register"  style="width:1000px;margin-bottom: 10px">
         <div class="title">【注册用户】专属条件</div>
         <div>
           <nav class="input" v-for="i in where.register" :style="i.key == 'certDate'?'width: 300px':'width: 210px'">
             <el-select v-if="i.key == 'status'" v-model="searchForm.status" placeholder="状态" size="medium">
               <el-option label="全部" :value="-1" />
               <el-option label="禁用" :value="2" />
               <el-option label="启用" :value="1" />
             </el-select>
    <span v-if="i.key == 'certDate'" class="flex-align">
            <el-date-picker  v-model="searchForm.certDate" type="daterange" range-separator="至" start-placeholder="认证开始日期"
                             end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%;"
                             size="medium" />
            </span>
             <el-select v-if="i.key == 'showCircles'" v-model="searchForm.showCircles" placeholder="圈子展示" size="medium">
               <el-option label="全部" :value="-1" />
               <el-option label="展示" :value="1" />
               <el-option label="不展示" :value="0" />
             </el-select>
             <el-select v-if="i.key == 'certType' " v-model="searchForm.certType" placeholder="认证类型" size="medium">
               <el-option label="全部类型" :value="-2" />
               <el-option label="未认证" :value="-1" />
               <el-option label="已认证" :value="1" />
               <!-- <el-option label="企业认证" :value="2"></el-option> -->
             </el-select>


           </nav>
         </div>

       </el-card>
       <el-card class="where" v-if="where.internal" style="width:1000px;margin-bottom: 10px">
         <div  class="title">
          【内部用户】专属条件
         </div>
         <div >
           <nav class="input" :style="(i.key == 'category' || i.key == 'register')?'width: 210px':'width: 300px'" v-for="i in where.internal">
            <span v-if="i.key == 'completeTime'" class="flex-align">
            <el-date-picker v-model="searchForm.completeTime" type="daterange" range-separator="至" start-placeholder="完善开始日期"
                            end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%;"
                            size="medium" />
            </span>
             <span v-if="i.key == 'updateTime'" class="flex-align">
            <el-date-picker v-model="searchForm.updateTime" type="daterange" range-separator="至" start-placeholder="更新开始日期"
                            end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%;"
                            size="medium" />
            </span>
             <el-select v-if="i.key == 'category'" style="width: 100%" v-model="searchForm.category" placeholder="身份筛选" size="medium" multiple>
               <el-option label="普通用户" value="0" />
               <el-option label="演讲嘉宾" value="2" />
               <el-option label="企业高管" value="1" />
             </el-select>
             <el-select
                 v-if="i.key == 'register'"
                 style="width: 100%"
                 v-model="searchForm.register" placeholder="是否注册" size="medium"
             >
               <el-option label="全部" value="0" />
               <el-option label="已注册" value="2" />
               <el-option label="未注册" value="1" />
             </el-select>
             <improve-personnel v-if="i.key == 'amId'"  :value.sync="searchForm.amId"></improve-personnel>

           </nav>
         </div>
       </el-card>
       <el-button style="margin-top: 10px" @click="reset">重置</el-button>
     </div>
<div class="module-title" v-if="sensitiveList.length || fieldList.length">
  导出字段
</div>
      <div class="exported-fields" v-if="fieldList.length">
<div class="tip-title">
  普通字段
</div>
        <el-checkbox-group v-model="fieldForm" >
          <el-checkbox style="margin: 10px"  :label="i.key"  v-for="i in fieldList">{{ i.name }}</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="exported-fields" v-if="sensitiveList.length">
        <div class="tip-title">
          敏感字段（导出敏感字段需要走飞书流程【敏感信息导出申请】），同时需要输入导出密钥
        </div>
        <el-checkbox-group v-model="sensitiveForm" >
          <el-checkbox style="margin: 10px"  :label="i.key"  v-for="i in sensitiveList">{{ i.name }}</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="module-title">
        导出目的(必填)
      </div>
      <div>
        <el-input type="textarea"
                  style="height: 120px;width: 63%"
                  :autosize="{ minRows: 4, maxRows: 8}"
                  placeholder="请填写导出用途或者个人导出备注"
                  v-model="form.describe"></el-input>
      </div>
    </div>
    <div>
      <el-button type="primary" style="margin-bottom: 50px" @click="visible">
        导出
      </el-button>
    </div>

    <div class="table">
      <el-table
          style="width:100%"
          :data="  table"
          stripe
          :header-cell-style="rowClass"
      >
        <el-table-column prop="id" label="ID" >

        </el-table-column>
        <el-table-column prop="username" label="姓名" >

        </el-table-column>
        <el-table-column prop="categoryName" label="数据库" >

        </el-table-column>
        <el-table-column prop="searchData" label="导出目的"  width="250">
          <template slot-scope="{ row }">
            <!--            <b-code-editor v-model="row.search" :auto-format="true" :smart-indent="true" theme="dracula" :indent-unit="4" :line-wrap="false" ref="editor"></b-code-editor>-->
            <el-tooltip  class="item" effect="dark" :content="row.describe" placement="top">
              <div class="clamp-1">{{ row.describe}}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="searchData" label="条件"  width="250">
          <template slot-scope="{ row }">
<!--            <b-code-editor v-model="row.search" :auto-format="true" :smart-indent="true" theme="dracula" :indent-unit="4" :line-wrap="false" ref="editor"></b-code-editor>-->
            <el-tooltip  class="item" effect="dark" :content="JSON.stringify(JSON.parse(row.searchData), null, '\t')" placement="top">
              <div slot="content">
                <div style="width: 200px;" v-html="JSON.stringify(JSON.parse(row.searchData), null, '<br>')"></div>
              </div>
              <div class="clamp-1">{{ JSON.parse(row.searchData) }}</div>
            </el-tooltip>

          </template>
        </el-table-column>
        <el-table-column prop="field" label="字段" >
          <template slot-scope="{ row }">
            <el-tooltip  class="item" effect="dark" :content="row.field" placement="top">

              <div class="clamp-1">{{ row.field }}</div>
            </el-tooltip>
          </template>

        </el-table-column>
        <el-table-column prop="time" label="时间">

        </el-table-column>
        <el-table-column prop="url" >
          <template slot="header" slot-scope="scope">
            文件
            <el-tooltip  class="item" effect="dark" content="刷新可以查看最新导出状态" placement="top">
            <span style="margin-left: 10px;cursor: pointer" @click="handleRefesh">
              <i class="el-icon-refresh refesh"   :style="refesh"></i>
              <span>刷新</span>
<!--                <img style="width: 12px;-->
<!--                                height: 12px;margin: 0px 5px 0px 5px "-->
<!--                     src="https://static-cdn.yfchuhai.com/www.yfchuhai.com/static/pc/img/vip_img/question_mark.png"-->
<!--                     alt="">-->
            </span>
            </el-tooltip>

          </template>
          <template slot-scope="{ row }">
            <el-button type="primary" @click="handleDown(row)"  :disabled="row.status != 2">
              {{changeStatus(row.status)}}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-card>

</template>

<script>
import improvePersonnel from "@/views/guest/component/improve-personnel.vue";
export default {
  components: {
    improvePersonnel,
  },

  data() {
    return {
      indexRe: 0,
      refesh:'',
      hasJsonFlag:true,
      p_arr: [],
      c_arr: [],
      typeList: [],
      businessList: [],
      subBusinessList: [],
      form: {
        downloadCategory:[],
        describe:'',
        secretKey:''
      },
      searchForm:{
        keyword: '',
        type: '',
        business: '',
        subBusiness:'',
        waId:'',
        province:'',
        city:'',
        bindEnterprise:'',
        jobLevel:'',
        createTime:'',
      },
      sensitiveForm:[],
      fieldForm:[],
      table:[],
      fieldList:[],
      sensitiveList:[],
      where:{},

      queryParams:{
        page:1,
        pageSize:10
      },
      timer:null
    }
  },
  beforeCreate() {
  },
  updated() {
  },
  created() {
    // console.log(JSON.parse(this.$route.query.data))
    this.getp();
    this.getList();
    this.countDown()
    this.getQueryData()
  },
  mounted() {
    this.getType()

  },
  activated() {



  },
  watch: {
    "searchForm.type"(newValue, oldValue) {
      if(this.searchForm.type){
        this.getType("1");
      }

    },
    "searchForm.business"(newValue, oldValue) {
      if (this.searchForm.business){
        this.getType("2");
      }

    },
    "form.downloadCategory"(newValue, oldValue) {
      this.exportFieldList(this.form.downloadCategory)
    },
  },
  methods: {
    reset(){
      this.searchForm=this.$options.data.call(this).searchForm;
    },
    async getQueryData(){
      if(this.$route.query.type){
        // console.log()
        this.$set(this.form,'downloadCategory',[+this.$route.query.type])
        let data =this.$route.query.data?JSON.parse(this.$route.query.data):{}

        this.form.downloadCategory=[this.$route.query.type]
        this.searchForm={...data}
        if(this.searchForm.province){
          this.getc(this.searchForm.province)
        }
        this.searchForm.city=data.city
        this.getType(1)
        this.searchForm.business=data.business
        this.getType(2)
        this.searchForm.subBusiness=data.subBusiness
        if(this.$route.query.type==2){
          let category =this.$route.query.category?JSON.parse(this.$route.query.category):[]
          this.searchForm.category=category
        }
        console.log(this.searchForm)
      }
    },
    resetForm(){
      this.fieldForm=this.$options.data.call(this).fieldForm
      this.sensitiveForm=this.$options.data.call(this).sensitiveForm
    },
  async  fileDownload (params){
  const  { data: res } = await this.$http.post(
      `/admin/ExportUserData/fileDownload`,{...params}
  );
    let link = document.createElement("a"); //创建a标签
    link.style.display = "none"; //使其隐藏
    link.href =res.data.url; //赋予文件下载地址.
    link.setAttribute("download", res.data.url); //设置下载属性 以及文件名
    document.body.appendChild(link); //a标签插至页面中
    link.click();
},

    handleDown(row){
      console.log(row)
      if(row.download_num && row.is_sensitive){
        this.$prompt(`<div>请输入导出敏感字段所需密钥</div>
<div style="color: rgba(128,128,128,0.65);font-size: 12px">如密钥失效请联系技术</div>` , '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true
        }).then(({ value }) => {
          if (value){
            const params = {
              secretKey:value,
              id:row.id
            }
            this.fileDownload(params)

          }else {
            this.$message('请输入密钥')

          }
        }).catch(() => {

        });
      }else {
        const params = {
          secretKey:'',
          id:row.id
        }
        this.fileDownload(params)
      }
      //强制触发a标签事件
    },
    handleRefesh(){
      this.indexRe = this.indexRe+1
      this.refesh = `transform:rotate(${360*this.indexRe}deg);`;
      this.getList()
    },
    countDown() {
      if (!this.timer) {
        this.show = false
        this.timer = setInterval(() => {
          this.getList()
        }, 10*1000)
      }
    },
    async  getList(){
      const  { data: res } = await this.$http.get(
          `/admin/ExportUserData/getExportDataLogList`,{params:this.queryParams}
      );
      if(res.data.list.length){
        this.table=res.data.list.map(item=>{
          let i = {
            ...item,
            search:item.searchData
          }
          return i
        })
      }

    },
    visible(){
      if(this.form.describe){
        if(this.sensitiveForm.length){
          this.$prompt(`<div>请输入导出敏感字段所需密钥</div>
<div style="color: rgba(128,128,128,0.65);font-size: 12px">如密钥失效请联系技术</div>` , '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            dangerouslyUseHTMLString: true
          }).then(({ value }) => {
            if (value){
              this.form.secretKey=value
              this.handleExportData()
            }else {
              this.visible()
              this.$message('请输入密钥')

            }
          }).catch(() => {

          });
        }else {
          this.handleExportData()
        }
      }else {
        this.$message.error('请填写导出目的')
      }

    },
    async handleExportData(){
      const form = {
        downloadCategory:this.form.downloadCategory.join(','),
        form:JSON.stringify(this.searchForm),
        field:[...this.fieldForm,...this.sensitiveForm].join(','),
        describe:this.form.describe,
        secretKey:this.form.secretKey
      }
      var { data: res } = await this.$http.post(
          `/admin/ExportUserData/exportData`,{
           ...form
          }
      );
      this.getList()
    },
    async exportFieldList(type){
      const { data: res } = await this.$http.post(
          `/admin/ExportUserData/exportFieldList?category=`+type.join(',')
      );
      this.fieldList=res.data.field;
      this.sensitiveList=res.data.sensitive;
      this.where=res.data.where
    },
    changeStatus(val){
      var key;
      switch (val) {
        case 1:
          key = "正在导出";
          break;
        case 2:
          key = "下载";
          break;
        case 3:
          key = "导出失败";
          break;
      }
      return key;
    },
    rowClass() {
      return "background:#F5F7FA;";
    },
    async getp() {
      const jj = await this.$store.dispatch("getProvince");
      this.p_arr = jj.data;
    },
    async getc(val) {
      this.searchForm.city = "";

      const jj = await this.$store.dispatch("getCity", val);
      if (jj.errorCode == 200) {
        this.c_arr = jj.data;
      }
    },
    async getType(val) {
      if (val == 1) {
        var { data: res } = await this.$http.get(
          `/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=${this.searchForm.type}&businessId=`
        );
        this.businessList = res.data.list;
        return;
      }
      if (val == 2) {
        var { data: res } = await this.$http.get(
          `/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=${this.searchForm.type}&businessId=${this.searchForm.business}`
        );
        this.subBusinessList = res.data.list;
        return;
      }
      var { data: res } = await this.$http.get(
        "/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=0&businessId="
      );
      this.typeList = res.data.list;
    },

  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="less" scoped>
.clamp-1{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.export_user_data{
  .module-title{
    font-size: 18px;
    font-weight: bold;
    margin: 20px 0px;
    &:nth-child(1){
      margin-top: 0px;
    }
  }
  .exported-fields{
    background: rgba(128, 128, 128, 0.11);
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
    max-width: 60%;
    .tip-title{
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
}
.where{
  font-size: 15px;
  .title{
    margin: 0px 0 10px;
    font-size: 16px;
  }
}
.refesh{
  transition: all 1s;
}
</style>
