<template>
    <div>

        <el-select v-model="key" placeholder="完善人员" size="medium">
            <el-option label="全部" :value="-1" />
            <el-option v-for="(item, index) in list" :key="index" :label="item.realName" :value="item.uid" />
        </el-select>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Number],
            default: "",
        },
    },
    data() {
        return {
            list: [],
            key: "",
        };
    },
    watch: {
        key(newValue, oldValue) {
            this.$emit("update:value", newValue);
            this.$emit("input", newValue);
        },
        value(val) {

            this.key = val;
        },
    },

    methods: {
        async getConfig() {
            const { data } = await this.$http.get("/admin/AuthMember/getList?page=1&pageSize=1000");
            this.list = data.data.list;
            this.key = this.value
        },
    },
    mounted() {
        this.getConfig();
    },
};
</script>

<style lang="less" scoped></style>
